.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2.5rem;
  text-align: center;
}

.title {
  font-family: 'Larken', serif;
  font-size: 7.5rem; 
  color: var(--color-primary);
  margin-bottom: 1rem;
  line-height: 1.1;
}

.description {
  font-size: 1.4rem; 
  max-width: 900px; 
  margin-bottom: 2rem;
}

.button-container {
  position: relative;
}

.cta-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding: 0.8rem 2rem;
  background: var(--color-button-bg);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
}

.unicorn {
  position: absolute;
  top: -30px;
  left: -4px;
  font-size: 2rem;
  opacity: 0;
}

.info-container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: var(--color-grey-1);
  border-radius: 50%;
  margin-right: 0.5rem;
}

.info-text {
  font-size: 0.75rem;
  color: var(--color-grey-1);
}

@media (max-width: 1200px) {
  .title {
    font-size: 6.5rem;
  }
  
  .description {
    font-size: 1.3rem;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 4rem;
  }

  .description {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 3.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1.1rem;
    padding: 0.7rem 1.8rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
  }
}