.products-container {
  display: flex;
  padding: 11rem 8rem;
  background-color: var(--color-cream-bg);
  font-family: 'Montserrat', sans-serif;
}
.products-left, .products-right {
  flex: 1;
}
.products-left {
  padding-right: 4rem;
}
.products-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.products-title {
  font-family: 'Larken', serif;
  font-size: 3rem;
  color: var(--color-black);
  margin-bottom: 0.5rem;
}
.products-subtitle {
  font-size: 1rem;
  color: var(--color-grey-2);
  margin-bottom: 2rem;
}
.carousel-container {
  position: relative;
  width: 70%;
  padding-bottom: 70%;
  perspective: 1000px;
  margin: 0 auto;
}
.carousel-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
}
.carousel-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.project-info h3 {
  font-family: 'Larken', serif;
  font-size: 2rem;
  color: var(--color-black);
  margin-bottom: 1rem;
}
.project-info p {
  font-size: 1rem;
  color: var(--color-grey-2);
  line-height: 1.6;
  margin-bottom: 1rem;
}
.product-link-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-primary, #007bff);
  color: var(--color-bg);
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-bottom: 2rem;
}
.product-link-button:hover {
  background-color: var(--color-black, #0056b3);
}
.carousel-controls {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.carousel-controls button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--color-black);
}
.carousel-controls span {
  font-size: 1rem;
  color: var(--color-grey-2);
}
@media (max-width: 1400px) {
  .products-container {
    padding: 8rem 6rem;
  }
  .products-title {
    font-size: 2.5rem;
  }
  .project-info h3 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1200px) {
  .products-container {
    padding: 6rem 4rem;
  }
  .products-left {
    padding-right: 3rem;
  }
  .carousel-container {
    width: 80%;
    padding-bottom: 80%;
  }
}
@media (max-width: 1024px) {
  .products-container {
    flex-direction: column;
    padding: 5rem 3rem;
    align-items: center;
  }
  .products-left, .products-right {
    width: 100%;
    max-width: 600px;
  }
  .products-left {
    padding-right: 0;
    margin-bottom: 3rem;
  }
  .carousel-container {
    width: 100%;
    padding-bottom: 100%;
    max-width: 500px;
  }
  .project-info {
    text-align: center;
  }
  .carousel-controls {
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .products-container {
    padding: 4rem 2rem;
  }
  .products-title {
    font-size: 2rem;
  }
  .products-subtitle, .project-info p {
    font-size: 0.9rem;
  }
  .project-info h3 {
    font-size: 1.5rem;
  }
  .carousel-container {
    width: 100%;
    padding-bottom: 100%;
    max-width: 400px;
  }
  .product-link-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .products-container {
    padding: 3rem 1.5rem;
  }
  .products-title {
    font-size: 1.8rem;
  }
  .products-subtitle, .project-info p {
    font-size: 0.8rem;
  }
  .project-info h3 {
    font-size: 1.3rem;
  }
  .carousel-container {
    width: 100%;
    padding-bottom: 100%;
    max-width: 300px;
  }
  .carousel-controls button {
    font-size: 1.2rem;
  }
  .carousel-controls span {
    font-size: 0.9rem;
  }
}