@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUQjIg1_i6t8kCHKm45_QphzQ.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUOjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_aZA3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm45xW5ryg.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_c5H3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_bZF3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_eJF3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_dJE3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTURjIg1_i6t8kCHKm45_epF3g3D_u50.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('https://fonts.gstatic.com/s/montserrat/v24/JTUPjIg1_i6t8kCHKm459WxRxC7m0dR7.woff2') format('woff2');
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-ExtraBoldItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Larken';
  src: url('./assets/fonts/Larken/LarkenDEMO-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}
