.about-container {
    display: flex;
    min-height: 100vh;
    background-color: var(--color-bg);
  }
  
  .about-left {
    flex: 0 0 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    align-items: flex-start;
  }
  
  .about-right {
    flex: 0 0 60%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: var(--color-bg);
  }
  
  .pulsating-circle {
    position: absolute;
    width: 700px;
    height: 700px;
    border-radius: 50%;
    background: radial-gradient(circle, var(--color-primary) 0%, rgba(232, 80, 46, 0) 70%);
    filter: blur(50px);
    opacity: 0.5;
    z-index: 0;
  }
  
  .about-title {
    font-family: 'Larken', serif;
    font-size: 3.5rem;
    color: var(--color-black);
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .about-subtitle {
    font-size: 1.1rem;
    color: var(--color-grey-2);
    line-height: 1.6;
  }
  
  .chat-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;
  }
  
  .message {
    display: flex;
    align-items: flex-start;
    max-width: 80%;
    gap: 0.5rem;
  }
  
  .profile-pic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    flex-shrink: 0;
  }
  
  .message-content {
    padding: 1rem;
    border-radius: 1rem;
    font-size: 0.9rem;
    line-height: 1.4;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-weight: 600;
  }
  
  .message.user {
    align-self: flex-end;
    flex-direction: row-reverse;
  }
  
  .message.bot {
    align-self: flex-start;
  }
  
  @media (max-width: 1200px) {
    .about-title {
      font-size: 3rem;
    }
  }
  
  @media (max-width: 1024px) {
    .about-container {
      flex-direction: column;
    }
  
    .about-left,
    .about-right {
      flex: 0 0 100%;
    }
  
    .about-title {
      font-size: 2.5rem;
    }
  
    .chat-container {
      max-width: 100%;
    }
  
    .pulsating-circle {
      width: 300px;
      height: 300px;
    }
  }
  
  @media (max-width: 768px) {
    .about-title {
      font-size: 2rem;
    }
  
    .about-subtitle {
      font-size: 1rem;
    }
  
    .message-content {
      font-size: 0.8rem;
    }
  }