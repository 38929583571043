:root {
  --color-primary: #E8502E;  
  --color-grey-1: #8C8B85;
  --color-grey-2: #595853;
  --color-black: #202016;
 --color-button-bg: linear-gradient(to right, #030303, #3B3B3B);
  --color-cream-bg: #ECECE0;
  --color-cream-text:#E3E3E0;
  --color-bg:#EFEEE8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  overflow-y: scroll;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--color-bg);
  color: var(--color-grey-2);
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--color-primary);
  transform-origin: 0%;
  z-index: 1001;
}

.section {
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.home-container, .about-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
