@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&family=Sedgwick+Ave&display=swap');

.bento-grid {
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg);
  padding: 2rem;
  gap: 2rem;
}

.card {
  backdrop-filter: blur(21px) saturate(188%);
  -webkit-backdrop-filter: blur(21px) saturate(188%);
  background-color: rgba(216, 210, 194, 0.39);
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-radius: 15px;
  padding: clamp(1rem, 3vw, 2rem);
}

.left-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.right-side {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(45deg, #050505, #333333);
  color: var(--color-cream);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.team-section {
  display: flex;
  flex-direction: column-reverse;
}

.team-title {
  font-family: 'Larken', sans-serif;
  font-weight: 700;
  font-size: clamp(2rem, 5vw, 3rem);
  text-align: center;
  margin-top: 2rem;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}

.team-member {
  position: relative;
  cursor: pointer;
  margin: 0 -10px 10px -10px;
}

.team-member img {
  width: clamp(50px, 8vw, 80px);
  height: clamp(50px, 8vw, 80px);
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 20%;
  transform: translateX(-50%);
  color: #333;
  padding: 1rem;
  border-radius: 8px;
  z-index: 20;
  width: 200px;
  text-align: center;
}

.emojis {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.emoji {
  font-size: 1.2rem;
  margin: 0 0.2rem;
}

.emoji:nth-child(1) { transform: translateY(-5px) rotate(-20deg); }
.emoji:nth-child(2) { transform: translateY(-10px); }
.emoji:nth-child(3) { transform: translateY(-5px) rotate(20deg); }

.tooltip h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
}

.tooltip p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.interests {
  font-family: 'Sedgwick Ave', cursive !important;
  font-size: 1rem;
  color: #666;
}

.reveal-links {
  display: grid;
  place-content: center;
  gap: 1rem;
  padding: 2rem;
}

.flip-link {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-size: clamp(3rem, 8vw, 8rem);
  font-weight: 900;
  text-transform: uppercase;
  line-height: 0.75;
  color: white;
  text-decoration: none;
}

.flip-link-content {
  display: block;
}

.flip-link-content-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flip-link-char {
  display: inline-block;
}

.flip-link:hover {
  color: var(--color-primary);
}

@media (min-width: 768px) and (max-width: 1700px) {
  .bento-grid {
    flex-direction: column;
    height: auto;
  }

  .left-side, .right-side {
    width: 100%;
  }

  .team-members {
    justify-content: center;
  }

  .team-member {
    margin: 0 -10px 10px -10px;
  }
}

@media (min-width: 1701px) {
  .bento-grid {
    flex-direction: row;
    height: 50vh;
  }

  .left-side {
    width: 30%;
  }

  .right-side {
    width: 70%;
  }

  .team-members {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .bento-grid {
    height: auto;
  }

  .team-members {
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .team-member {
    margin: 0 -15px 10px -15px;
  }

  .right-side {
    min-height: 250px;
  }

  .flip-link {
    font-size: clamp(2.5rem, 7vw, 10rem);
  }
}

@media (max-width: 480px) {
  .bento-grid {
    padding: 1rem;
  }

  .card {
    padding: 1rem;
  }

  .team-title {
    font-size: 1.8rem;
  }

  .team-members {
    padding-left: 25px;
    padding-right: 25px;
  }

  .team-member {
    margin: 0 -12px 10px -12px;
  }

  .team-member img {
    width: 50px;
    height: 50px;
  }

  .flip-link {
    font-size: clamp(2rem, 6vw, 8rem);
  }
}