.navbar {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 15px 0;
  box-shadow: 0 8px 32px 0 rgba(89, 88, 83, 0.37);
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 800px;
  z-index: 1000;
  border-radius: 25px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-logo {
  color: var(--color-black);
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  justify-content: center;
  flex-grow: 1; 
}

.navbar-links a {
  color: var(--color-black);
  text-decoration: none;
  margin: 0 0.5rem; 
  font-family: 'Larken', sans-serif;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-links a:hover,
.navbar-links a.active {
  background-color: var(--color-black);
  color: var(--color-cream-bg);
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle span {
  height: 2px;
  width: 25px;
  background: var(--color-black);
  margin: 4px 0;
  transition: all 0.3s ease;
}

@media (max-width: 1200px) {
  .navbar {
    width: 95%;
  }
}

@media (max-width: 992px) {
  .navbar-links a {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
}

@media (max-width: 768px) {
  .navbar {
    top: 10px;
  }

  .navbar-container {
    flex-wrap: wrap;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 0 0 25px 25px;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links a {
    margin: 10px 0;
    text-align: center;
  }

  .navbar-toggle {
    display: flex;
  }
}

@media (max-width: 480px) {
  .navbar {
    width: 100%;
    border-radius: 0;
    top: 0;
  }

  .navbar-logo {
    font-size: 1.2rem;
  }

  .navbar-links a {
    font-size: 0.9rem;
  }

  .navbar-container {
    padding: 0 10px;
  }
}